@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600&display=swap');


*{
    font-family: 'Nunito', sans-serif;
    margin:0; 
    padding:0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none; 
    border:none;
    transition: all .2s linear;
    color: white;
}


@tailwind base;
@tailwind components;
@tailwind utilities;